import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../layout/Main'
import AdminLayout from '../layout/admin/AdminLayout'
import SchoolLayout from '../layout/school/SchoolLayout'
import TeacherLayout from '../layout/teacher/TeacherLayout'
import student from "./studentRoutes"

Vue.use(VueRouter)

export const commonRoutes = [{
        path: '/',
        name: 'studentLogin',
        component: () =>
            import ("../views/StudentLogin"),
        meta: {
            role: [1, 2, 3, 4],
            title: '学生登录'
        }
    },
    {
        path: '/m',
        name: 'login',
        component: () =>
            import ("../views/Login"),
        meta: {
            role: [1, 2, 3, 4],
            title: '登录'
        }
    },
    {
        path: "/exam",
        redirect: "/exam/index",
        name: "exam",
        component: Main,
        meta: {
            title: "学生实训",
            icon: "&#xe637;",
            role: 5,
        },
        children: [{
            path: "/exam/index",
            name: "examIndex",
            component: () =>
                import ("../views/student/Exam"),
            meta: {
                title: "学生实训",
                role: 5,
                parentName: ["exam"],
            },
        }],
    },
    {
        path: '/livedata/trainLivedatas',
        name: 'trainLiveDatas',
        component: () =>
            import ("../views/livedata/liveDatas"),
        meta: {
            title: '查看数据面板',
            role: 4,
            hidden: true
        }
    },
    {
        path: '/livedata/livedatas',
        name: 'studentLiveDatas',
        component: () =>
            import ("../views/livedata/liveDatas"),
        meta: {
            title: '数据面板',
            role: 5,
            hidden: true
        }
    },
    {
        path: '/teacher/dataCenter/replayData',
        name: 'dataCenterReplayData',
        component: () =>
            import ('../views/teacher/dataCenter/ReplayData.vue'),
        meta: {
            title: '复盘数据',
            role: 4,
            hidden: true
        },
    },
    {
        path: '/teacher/dataCenter/liveReplay',
        name: 'dataCenterliveReplay',
        component: () =>
            import ('../views/teacher/dataCenter/liveReplay.vue'),
        meta: {
            title: '复盘数据',
            role: 4,
            hidden: true
        },
    },
    {
        path: '/toSeeLive/StudentLive',
        name: 'seeStudentLive',
        component: () =>
            import ('../views/student/SeeStudentLive.vue'),
        meta: {
            title: '查看学生直播',
            role: 4,
            hidden: true
        },
    },
    {
        path: '/goodDetail',
        name: 'goodDetail',
        component: () => import("../views/student/GoodDetail"),
        meta: {
            role: 5,
            title: '商品详情'
        }
    },
]

export const adminRoutes = [{
    path: '/admin',
    name: 'admin',
    redirect: '/educational/school',
    component: AdminLayout,
    children: [{
            path: '/educational',
            name: 'educational',
            redirect: '/educational/school',
            component: Main,
            meta: {
                title: '教务管理',
                icon: '&#xe6b4;',
                role: 1
            },
            children: [{
                    path: '/educational/school',
                    name: 'educationalSchool',
                    component: () =>
                        import ('../views/admin/educationalManage/Index.vue'),
                    meta: {
                        title: '学校管理',
                        role: 1,
                        parentName: ['educational']
                    },
                },
                {
                    path: '/educational/schoolAdmin',
                    name: 'SchoolAdmin',
                    component: () =>
                        import ('../views/admin/educationalManage/SchoolAdmin.vue'),
                    meta: {
                        title: '学校管理员',
                        role: 1,
                        parentName: ['educational']
                    },
                },
                // {
                //     path: '/educational/trainAdmin',
                //     name: 'TrainAdmin',
                //     component: () =>
                //         import ('../views/admin/educationalManage/TrainAdmin.vue'),
                //     meta: {
                //         title: '训练管理员',
                //         role: 1,
                //         parentName: ['educational']
                //     },
                // },
            ]
        },
        {
            path: '/sortManagement',
            name: 'sortManagement',
            redirect: '/sortManagement/management',
            component: Main,
            meta: {
                title: '分类管理',
                icon: '&#xe6ae;',
                role: 1
            },
            children: [{
                path: '/sortManagement/management',
                name: 'sortManagement',
                component: () =>
                    import ('../views/admin/sortManagement/management.vue'),
                meta: {
                    title: '分类管理',
                    role: 1,
                    parentName: ['sortManagement']
                },
            }, ]
        },
        {
            path: '/liveStreaming',
            name: 'liveStreaming',
            redirect: '/liveStreaming/practicalTrain',
            component: Main,
            meta: {
                title: '直播实训题管理',
                icon: '&#xe60b;',
                role: 1
            },
            children: [
                {
                    path: '/liveStreaming/commodity',
                    name: 'commodity',
                    component: () =>
                        import ('../views/admin/liveStreaming/commodity.vue'),
                    meta: {
                        title: '商品库',
                        role: 1,
                        parentName: ['liveStreaming']
                    },
                },
                {
                    path: '/bagManage/index',
                    name: 'bagManageIndex',
                    component: () =>
                        import ('../views/admin/bagManage/Index.vue'),
                    meta: {
                        title: '福袋管理',
                        role: 1,
                        parentName: ['liveStreaming']
                    },
                },
                {
                    path: '/scriptManagement/ScriptListData',
                    name: 'ScriptListData',
                    component: () =>
                        import ('../views/admin/scriptManagement/ScriptListData.vue'),
                    meta: {
                        title: '脚本管理',
                        role: 1,
                        parentName: ['liveStreaming']
                    },
                },
                {
                    path: '/adLaunch/index',
                    name: 'adLaunchIndex',
                    component: () =>
                        import ('../views/admin/adLaunch/Index.vue'),
                    meta: {
                        title: '广告投放',
                        role: 1,
                        parentName: ['liveStreaming']
                    },
                },
                {
                    path: '/liveStreaming/practicalTrain',
                    name: 'PracticalTrain',
                    component: () =>
                        import ('../views/admin/practicalTrainManage/PracticalTrainManage.vue'),
                    meta: {
                        title: '实训试卷管理',
                        role: 1,
                        parentName: ['liveStreaming']
                    },
                },
                {
                    path: '/liveStreaming/practicalTrainOperate',
                    name: 'PracticalTrainOperate',
                    component: () =>
                        import ('../views/admin/practicalTrainManage/PracticalTrainOperate.vue'),
                    meta: {
                        title: '创建实训试卷',
                        role: 1,
                        parentName: ['liveStreaming', "PracticalTrain"],
                        hidden: true
                    },
                },
                {
                    path: '/liveStreaming/commodity-establish',
                    name: 'commodity',
                    component: () =>
                        import ('../views/admin/liveStreaming/commodity-establish.vue'),
                    meta: {
                        title: '商品库',
                        role: 1,
                        parentName: ['liveStreaming'],
                        hidden: true
                    },
                },
                {
                    path: "/liveStreaming/newComManagement",
                    name: "newComManagement",
                    redirect: "/liveStreaming/newComManagement/message",
                    component: () =>
                        import ("../views/admin/liveStreaming/newComManagement.vue"),
                    children: [{
                            path: "/liveStreaming/newComManagement/message",
                            name: "one",
                            component: () =>
                                import ("../views/admin/liveStreaming/newComManagementMessage.vue"),
                            meta: {
                                title: "填写商品",
                                role: 1,
                                parentName: [
                                    'liveStreaming',
                                    'commodity',
                                ],
                                hidden: true,
                            },
                        },
                        {
                            path: "/liveStreaming/newScriptManagementData",
                            name: "two",
                            component: () =>
                                import ("../views/admin/liveStreaming/newComManagementData.vue"),
                            meta: {
                                title: "填写商品",
                                role: 1,
                                parentName: [
                                    'liveStreaming',
                                    'commodity',
                                ],
                                hidden: true,
                            },
                        },
                        {
                            path: "/liveStreaming/newComManagementCreate",
                            name: "two",
                            component: () =>
                                import ("../views/admin/liveStreaming/newComManagementCreate.vue"),
                            meta: {
                                title: "填写商品",
                                role: 1,
                                parentName: [
                                    'liveStreaming',
                                    'commodity',
                                ],
                                hidden: true,
                            },
                        },
                        {
                            path: "/liveStreaming/newComManagementScript",
                            name: "three",
                            component: () =>
                                import ("../views/admin/liveStreaming/newComManagementScript.vue"),
                            meta: {
                                title: "填写脚本数据",
                                role: 1,
                                parentName: [
                                    'liveStreaming',
                                    'commodity',
                                ],
                                hidden: true,
                            },
                        },
                        {
                            path: "/liveStreaming/newComManagementCoupon",
                            name: "four",
                            component: () =>
                                import ("../views/admin/liveStreaming/newComManagementCoupon.vue"),
                            meta: {
                                title: "填写脚本数据",
                                role: 1,
                                parentName: [
                                    'liveStreaming',
                                    'commodity',
                                ],
                                hidden: true,
                            },
                        },
                        {
                            path: "/liveStreaming/newComManagementAnswers",
                            name: "five",
                            component: () =>
                                import ("../views/admin/liveStreaming/newComManagementAnswers.vue"),
                            meta: {
                                title: "填写脚本数据",
                                role: 1,
                                parentName: [
                                    'liveStreaming',
                                    'commodity',
                                ],
                                hidden: true,
                            },
                        },
                        {
                            path: "/liveStreaming/newComManagementContent",
                            name: "six",
                            component: () =>
                                import ("../views/admin/liveStreaming/newComManagementContent.vue"),
                            meta: {
                                title: "脚本分类内容",
                                role: 1,
                                parentName: [
                                    'liveStreaming',
                                    'commodity',
                                ],
                                hidden: true,
                            },
                        },
                        {
                            path: "/liveStreaming/newComManagementSucceed",
                            name: "seven",
                            component: () =>
                                import ("../views/admin/liveStreaming/newComManagementSucceed.vue"),
                            meta: {
                                title: "创建成功",
                                role: 1,
                                parentName: [
                                    'liveStreaming',
                                    'commodity',
                                ],
                                hidden: true,
                            },
                        },

                    ],
                    meta: {
                        title: "填写商品",
                        role: 1,
                        parentName: [
                            'liveStreaming',
                            'commodity',
                        ],
                        hidden: true,
                    },
                },
                {
                    path: '/scriptManagement/ShowScriptListData',
                    name: 'ShowScriptListData',
                    component: () =>
                        import ('../views/admin/scriptManagement/ShowScriptListData.vue'),
                    meta: {
                        title: '预览数据',
                        role: 1,
                        parentName: ['liveStreaming', 'ScriptListData', ],
                        hidden: true
                    },
                },
                {
                    path: "/scriptManagement/newScriptManagement",
                    name: "newScriptManagement",
                    redirect: "/scriptManagement/newScriptManagement/one",
                    component: () =>
                        import ("../views/admin/scriptManagement/newScriptManagement.vue"),
                    children: [{
                            path: "/scriptManagement/newScriptManagement/one",
                            name: "one",
                            component: () =>
                                import ("../views/admin/scriptManagement/newScriptManagementOne.vue"),
                            meta: {
                                title: "脚本名称",
                                role: 1,
                                parentName: [
                                    'liveStreaming',
                                    'ScriptListData',
                                ],
                                hidden: true,
                            },
                        },
                        {
                            path: "/scriptManagement/newScriptManagement/two",
                            name: "two",
                            component: () =>
                                import ("../views/admin/scriptManagement/newScriptManagementTwo.vue"),
                            meta: {
                                title: "商品列表数据",
                                role: 1,
                                parentName: [
                                    'liveStreaming',
                                    'ScriptListData',
                                ],
                                hidden: true,
                            },
                        },
                        {
                            path: "/scriptManagement/newScriptManagement/three",
                            name: "three",
                            component: () =>
                                import ("../views/admin/scriptManagement/newScriptManagementThree.vue"),
                            meta: {
                                title: "脚本信息",
                                role: 1,
                                parentName: [
                                    'liveStreaming',
                                    'ScriptListData',
                                ],
                                hidden: true,
                            },
                        },
                        {
                            path: "/scriptManagement/newScriptManagement/four",
                            name: "four",
                            component: () =>
                                import ("../views/admin/scriptManagement/newScriptManagementFour.vue"),
                            meta: {
                                title: "直播间数据",
                                role: 1,
                                parentName: [
                                    'liveStreaming',
                                    'ScriptListData',
                                ],
                                hidden: true,
                            },
                        },
                        {
                            path: "/scriptManagement/newScriptManagement/success",
                            name: "success",
                            component: () =>
                                import ("../views/admin/scriptManagement/newScriptSuccess.vue"),
                            meta: {
                                title: "创建成功",
                                role: 1,
                                parentName: [
                                    'liveStreaming',
                                    'ScriptListData',
                                ],
                                hidden: true,
                            },
                        },
                    ],
                    meta: {
                        title: "创建脚本",
                        role: 1,
                        parentName: [
                            'liveStreaming',
                            'ScriptListData',
                        ],
                        hidden: true,
                    },
                },
                {
                    path: '/adLaunch/create',
                    name: 'adLaunchCreate',
                    component: () =>
                        import ('../views/admin/adLaunch/CreateAdLaunch.vue'),
                    meta: {
                        title: '创建广告数据',
                        role: 1,
                        parentName: ['liveStreaming', 'adLaunchIndex'],
                        hidden: true
                    },
                },
                {
                    path: '/bagManage/create',
                    name: 'bagManageCreate',
                    component: () =>
                        import ('../views/admin/bagManage/CreateBag.vue'),
                    meta: {
                        title: '创建福袋',
                        role: 1,
                        parentName: ['liveStreaming', 'bagManageIndex'],
                        hidden: true
                    },
                },
            ]
        },

    ]
}, ]
export const schoolRoutes = [{
    path: '/school',
    name: 'school',
    redirect: '/school/teacherManage/Index',
    component: SchoolLayout,
    children: [{
        path: '/school/teacherManage',
        name: 'schoolTeacherManage',
        redirect: '/school/teacherManage/Index',
        component: Main,
        meta: {
            title: '教务管理',
            icon: '&#xe6b4;',
            role: 2
        },
        children: [{
            path: '/school/teacherManage/Index',
            name: 'educationalTeacher',
            component: () =>
                import ('../views/school/teacherManage/Index.vue'),
            meta: {
                title: '教师管理',
                role: 2,
                parentName: ['schoolTeacherManage']
            },
        }, ]
    }, ]
}]
export const teacherRoutes = [{
    path: '/teacher',
    name: 'teacher',
    redirect: '/teacher/classManage/Index',
    component: TeacherLayout,
    children: [{
        path: '/teacher/classManage',
        name: 'teacherClassManage',
        redirect: '/teacher/classManage/Index',
        component: Main,
        meta: {
            title: '教务中心',
            icon: '&#xe6b4;',
            role: 4
        },
        children: [{
            path: '/teacher/classManage/Index',
            name: 'teacherClassManageIndex',
            component: () =>
                import ('../views/teacher/classManage/Index.vue'),
            meta: {
                title: '班级管理',
                role: 4,
                parentName: ['teacherClassManage']
            },
        }, {
            path: '/teacher/classManage/studentManage',
            name: 'StudentManage',
            component: () =>
                import ('../views/teacher/classManage/StudentManage.vue'),
            meta: {
                title: '学生管理',
                role: 4,
                parentName: ['teacherClassManage']
            },
        }, ]
    }, {
        path: '/school/practical',
        name: 'schoolPractical',
        redirect: '/school/practical/index',
        component: Main,
        meta: {
            title: '实训中心',
            icon: '&#xe6b4;',
            role: 4
        },
        children: [{
            path: '/school/practical/index',
            name: 'Practical',
            component: () =>
                import ('../views/school/practical/index.vue'),
            meta: {
                title: '实训中心',
                role: 4,
                parentName: ['schoolPractical']
            },
        }, {
            path: '/school/practical/addDrill',
            name: 'Practical',
            component: () =>
                import ('../views/school/practical/addDrill.vue'),
            meta: {
                title: '创建训练',
                role: 4,
                parentName: ['schoolPractical'],
                hidden: true
            },
        }, {
            path: '/school/practical/streaming',
            name: 'Practical',
            component: () =>
                import ('../views/school/practical/streaming.vue'),
            meta: {
                title: '查看直播',
                role: 4,
                parentName: ['schoolPractical'],
                hidden: true
            },
        }]
    },
    //     {
    //     path: "/teacher/taskData",
    //     name: "TeacherTaskData",
    //     redirect: "/teacher/taskData/index",
    //     component: Main,
    //     meta: {
    //         title: '作业数据',
    //         icon: '&#xe6b4;',
    //         role: 4
    //     },
    //     children: [{
    //         path: "/teacher/taskData/index",
    //         name: "TeacherTaskDataIndex",
    //         component: () => import("../views/teacher/taskData/TaskData.vue"),
    //         meta: {
    //             title: '作业数据',
    //             role: 4,
    //             parentName: ['TeacherTaskData']
    //         }
    //     }]
    // },
        {
        path: '/teacher/dataCenter',
        name: 'teacherDataCenter',
        redirect: '/teacher/dataCenter/index',
        component: Main,
        meta: {
            title: '数据中心',
            icon: '&#xe6b4;',
            role: 4
        },
        children: [{
            path: '/teacher/dataCenter/index',
            name: 'teacherDataCenterIndex',
            component: () =>
                import ('../views/teacher/dataCenter/Index.vue'),
            meta: {
                title: '得分榜单',
                role: 4,
                parentName: ['teacherDataCenter']
            }
        }]
    }]
}]
export const studentRoutes = student;

const createRouter = () => new VueRouter({
    scrollBehavior: () => ({ y: 0 }),
    base: process.env.BASE_URL,
    routes: commonRoutes.concat(commonRoutes).concat(adminRoutes).concat(schoolRoutes).concat(teacherRoutes).concat(studentRoutes)
})

const router = createRouter()

export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher
}

export default router
