<template>
    <div class="student-container">
        <div class="student-left">
            <div class="student-avatar-logo">
                <i class="iconfont">&#xe656;</i>
                <span class="student-avatar-title">{{appName}}</span>
            </div>
            <div class="student-avatar-user">
                <div class="student-avatar">
                    <img :src="userIcon" alt=""/>
                </div>
                <div class="student-icon">
                    <div class="user-name text-overflow">{{ name }}</div>
                    <i class="iconfont" @click="logout">&#xe88a;</i>
                </div>
            </div>
            <div class="menu-box">
                <template v-if="schoolMenu.length > 0">
                    <el-scrollbar class="levelOne-box">
                        <router-link :to="levelOne.path" class="levelOne"
                                     :class="{current:levelOne.name === $route.name ||($route.meta.parentName && $route.meta.parentName.includes(levelOne.name)),}"
                                     v-for="levelOne in schoolMenu"
                                     :key="levelOne.name">
                            <i class="iconfont" v-html="levelOne.meta.icon"></i>
                            <span class="one-text">{{ levelOne.meta.title }}</span>
                        </router-link>
                    </el-scrollbar>
                </template>
            </div>
        </div>
        <div class="student-right">
            <div class="levelTwo-wrapper" v-if="levelTwoMenu.length > 0 && showTab">
                <router-link
                        :to="levelTwo.path"
                        class="levelTwo"
                        :class="{
                        current: levelTwo.name === $route.name || $route.meta.parentName.includes(levelTwo.name),
                        single: levelTwoMenu.length === 1,
                        wider: ['identifyCenter', 'classification'].some((item) => $route.meta.parentName == item),}"
                        v-for="levelTwo in levelTwoMenu"
                        :key="levelTwo.name">
                    <div class="two-text">
                        <span class="text-left"></span>
                        <span class="text-main">{{ levelTwo.meta.title }}</span>
                        <div class="wider-box"></div>
                    </div>
                </router-link>
            </div>
            <div class="content">
                <router-view/>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";
    import {Logout} from "@/utils/apis";
    import config from "@/utils/config";

    export default {
        name: "SchoolLayout",
        data() {
            return {
                appName: config.appName,
                activeIndex: "",
                name: localStorage.getItem('nickname') || null,
                userIcon: require("../../assets/image/blank_headPic.png"),
                role: "",
                levelTwoMenu: [],
                showTab: true,
            };
        },
        computed: mapState({
            schoolMenu: (state) => state.schoolMenu[0].children,
        }),
        watch: {
            $route(route) {
                this.getLevelTwoMenu(route);
            },
        },
        created() {
            this.role = localStorage.getItem("role");
            let avatar = localStorage.getItem("schoolAvatar");
            this.userIcon = avatar ? avatar : require("../../assets/image/blank_headPic.png")
            if (this.$route.meta.hasOwnProperty("parentName")) {
                this.activeIndex = this.$route.meta.parentName[0];
            } else {
                this.activeIndex = this.$route.name;
            }
            this.getLevelTwoMenu(this.$route);
        },
        methods: {
            getLevelTwoMenu(route) {
                if (route.meta.level && route.meta.level === 2) {
                    this.showTab = false;
                } else {
                    this.showTab = true;
                }
                const twoArr = this.schoolMenu.find((item) => {
                    if (route.meta.parentName) {
                        return route.meta.parentName[0] === item.name;
                    } else {
                        return item.name === route.name;
                    }
                });
                if (twoArr.children) {
                    this.levelTwoMenu = twoArr.children.filter((item) => !item.meta.hidden);
                } else {
                    this.levelTwoMenu = [];
                }
            },
            logout() {
                Logout().then((res) => {
                    localStorage.clear();
                    this.$message.success(res.msg);
                    this.$router.push("/m");
                });
            },
        },
    };
</script>

<style scoped lang="scss">
    .student-container {
        height: 100%;
        display: flex;
        background: #eeeff3;
    }

    .student-left {
        min-width: 240px;
        height: 100%;
        display: flex;
        flex-direction: column;
        margin-right: 20px;

        .student-avatar-logo {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 16px 0 0 10px;

            i {
                font-size: 30px;
                color: #2821fc;
            }

            .student-avatar-title {
                font-size: 20px;
                font-weight: 400;
                display: inline-block;
                color: #332d42;
                margin-left: 10px;
            }
        }

        .student-avatar-user {
            margin-top: 89px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .student-avatar {
                width: 84px;
                height: 84px;
                border-radius: 50%;
                overflow: hidden;

                img {
                    max-height: 100%;
                    max-width: 100%;
                }
            }

            .student-icon {
                margin-top: 18px;
                font-size: 18px;
                font-weight: 500;
                color: #332d42;
                max-width: 200px;
                display: flex;
                align-items: center;

                i {
                    font-size: 20px;
                    margin-left: 9px;
                    cursor: pointer;
                }
            }
        }
    }

    .menu-box {
        margin-top: 106px;
        flex: 1;
        height: 1%;

        .levelOne-box {
            position: relative;
            width: 100%;
            height: 100%;
            padding-top: 20px;
            display: flex;
            flex-direction: column;

            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;

                .el-scrollbar__view {
                    height: 100%;
                }
            }

            .levelOne {
                height: 60px;
                padding: 0 10px 0 30px;
                display: flex;
                align-items: center;
                color: #666;
                line-height: 1;
                transition: all 0.3s;

                .iconfont {
                    font-size: 18px;
                }

                .one-text {
                    font-size: 18px;
                    margin-left: 20px;
                    line-height: 18px;
                }

                &:hover {
                    color: #2821fc;
                }

                &.current {
                    color: #fff;
                    background: #2821fc;
                    border-radius: 0px 20px 20px 0px;

                    .icon {
                        background: #2821fc;
                    }
                }
            }
        }
    }

    .student-right {
        height: 100%;
        flex: 1;
        width: 1%;
        display: flex;
        flex-direction: column;

        .levelTwo-wrapper {
            margin: 20px 0 0 39px;
            display: flex;

            .two-text {
                width: 154px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                font-weight: 500;
                color: #5b5b6d;
                position: relative;

                .text-main {
                    z-index: 10;
                }
            }

            .current {
                .two-text {
                    background: #ffffff;
                    color: #4b45ff;
                    border-radius: 20px 20px 0 0;

                    .wider-box {
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 194px;
                        height: 20px;
                        background-color: #fff;

                        &::before {
                            content: "";
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            width: 20px;
                            height: 20px;
                            background-color: #EEEFF3;
                            z-index: 999;
                            border-radius: 0 0 20px 0;
                        }

                        &::after {
                            content: "";
                            position: absolute;
                            right: 0;
                            bottom: 0;
                            width: 20px;
                            height: 20px;
                            background-color: #EEEFF3;
                            z-index: 999;
                            border-radius: 0 0 0 20px;
                        }
                    }
                }
            }
        }

        .content {
            background: #ffffff;
            flex: 1;
            height: 1%;
            border-radius: 20px 0px 0px 20px;
        }
    }
</style>
